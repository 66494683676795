<template>
    <LiefengContent>
        <template v-slot:title>精准推送管理</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="menuList"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="onSelect"
            ></LiefengTable>

            <!-- 新增修改弹窗 -->
            <LiefengModal class="map" :fullscreen="true" :title="JSON.stringify(currentFormData) !== '{}' ? '新增' : '新增'" :value="saveStatus" @input="saveStatusFn">
                <template v-slot:contentarea>
                    <div v-if="JSON.stringify(currentFormData) !== '{}'" style="height: 100%; overflow: hidden">
                        <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                            <FormItem label="推送内容" prop="title">
                                <Input v-model.trim="currentFormData.title" placeholder="限20字" maxlength="20"></Input>
                            </FormItem>
                            <FormItem label="提示说明" prop="remark">
                                <Input v-model.trim="currentFormData.remark" placeholder="限20字" maxlength="20"></Input>
                            </FormItem>
                            <FormItem label="来源机构" prop="orgName">
                                <Input v-model.trim="currentFormData.orgName" placeholder="限20字" maxlength="20"></Input>
                            </FormItem>
                            <FormItem label="编码或链接" prop="orgName">
                                <Input v-model.trim="currentFormData.qrCode" placeholder="限100字" maxlength="100"></Input>
                            </FormItem>
                            <FormItem label="推送对象" prop="orgName">
                                <Select v-model="currentFormData.labelCode" transfer placeholder="请选择标签画像" multiple filterable>
                                    <Option :value="item.labelCode" v-for="(item, index) in labelList" :key="index">{{ item.labelName }}</Option>
                                </Select>
                            </FormItem>
                        </Form>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="saveStatusFn(false)">取消</Button>
                    <Button type="primary" @click="submit">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [],
            currentFormData: {
                title: "",
                remark: "",
                orgName: JSON.parse(sessionStorage.getItem("userDetail")).orgName,
                qrCode: "",
                labelCode: [],
            },
            validateForm: {
                title: [{ required: true, message: "请输入服务事项", trigger: "blur" }],
                type: [{ required: true, type: "array", min: 1, message: "请选择服务类型", trigger: "change" }],
            },

            saveStatus: false,
            labelList: [],
        }
    },
    methods: {
        submit() {
            this.$Modal.confirm({
                title: "推送须知",
                content: "当前推送无法撤销，确定是否推送",
                okText: "立即推送",
                onOk: () => {
                    this.$refs.form.validate(status => {
                        if (status) {
                            this.$post("/gateway/api/lfuser/userLabel/sendMessage", {
                                ...this.currentFormData,
                                labelCode: this.currentFormData.labelCode.join(","),
                            })
                                .then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success({
                                            background: true,
                                            content: "保存成功",
                                        })
                                        // this.getSceneData(this.page)
                                        this.saveStatusFn(false)
                                    } else {
                                        this.$Message.error({
                                            background: true,
                                            content: res.desc,
                                        })
                                    }
                                })
                                .catch(err => {
                                    console.log(err)
                                    this.$Message.error({
                                        background: true,
                                        content: "保存失败，请联系管理员处理",
                                    })
                                })
                        }
                    })
                },
            })
        },
        saveStatusFn(status) {
            this.saveStatus = status
        },
        append(data) {
            ;(this.currentFormData = {
                title: "",
                remark: "",
                orgName: JSON.parse(sessionStorage.getItem("userDetail")).orgName,
                qrCode: "",
                labelCode: [],
            }),
                this.saveStatusFn(true)
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.getSceneData(obj.page)
        },
        getSceneData(page) {
            this.loading = true
            this.$get("/gateway/apps/o2o/api/o2o/test15min/query", {
                page,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 点击了地图

        async getDictTye() {
            await this.$get("/gateway/api/lfuser/userLabel/getValidLabelConfig", {
                appCode: "",
            }).then(res => {
                if (res.code == 200) {
                    this.labelList = res.dataList
                }
            })
        },
    },
    async created() {
        // this.getSceneData(1)
        this.getDictTye()
    },
}
</script>

<style lang='less'>
.leftMenu {
    // position: fixed;
    // top: 60px;
    // left: 0px;
    width: calc(100% - 600px);
    // bottom: 0;
    background: #ffffff;
    // overflow: auto;
    float: left;
}
.rightEdit {
    float: left;
    // position: fixed;
    // top: 60px;
    height: 600px;
    width: 600px;
    // right: 0;
    bottom: 0;
    // overflow: auto;
    border-left: #ddd solid 1px;
}
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 10px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
